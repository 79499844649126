var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "StandardTable" },
    [
      _vm.showPage
        ? _c(
            "Page",
            _vm._g(
              {
                staticClass: "page",
                attrs: {
                  total: _vm.total,
                  "show-total": "",
                  "show-sizer": "",
                  "show-elevator": "",
                  transfer: "",
                  "page-size": _vm.pageSize,
                  current: _vm.currentPage,
                  "page-size-opts": _vm.pageSizeOpts
                }
              },
              _vm.standardTableEvent
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showTable
        ? _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "Table",
                _vm._g(
                  {
                    ref: "table",
                    staticClass: "table",
                    attrs: {
                      multiple: "",
                      total: _vm.total,
                      columns: _vm.columns,
                      height: true,
                      data: _vm.data
                    }
                  },
                  _vm.standardTableEvent
                )
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }