var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Delegate" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _vm.modalConfig.control
          ? _c("mutipleSelectPop", {
              ref: "dialogtest",
              attrs: {
                "node-id": _vm.modalConfig.nodeId,
                "is-mutiple": false,
                "is-use": false
              },
              on: { getResult: _vm.getResult }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "Button",
          {
            attrs: { disabled: _vm.buttonDisabled },
            on: { click: _vm.cancel }
          },
          [_vm._v("\n      取消\n    ")]
        ),
        _vm._v(" "),
        _c(
          "Button",
          {
            attrs: { type: "primary", disabled: _vm.buttonDisabled },
            on: { click: _vm.confirm }
          },
          [_vm._v("\n      确定\n    ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }