import { render, staticRenderFns } from "./FormItemComponent.vue?vue&type=template&id=264ab5f9&scoped=true&"
import script from "./FormItemComponent.vue?vue&type=script&lang=js&"
export * from "./FormItemComponent.vue?vue&type=script&lang=js&"
import style0 from "./FormItemComponent.vue?vue&type=style&index=0&id=264ab5f9&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "264ab5f9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\mid\\工作流文件\\jflow-plugin-工作流开发\\jflow-plugin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('264ab5f9')) {
      api.createRecord('264ab5f9', component.options)
    } else {
      api.reload('264ab5f9', component.options)
    }
    module.hot.accept("./FormItemComponent.vue?vue&type=template&id=264ab5f9&scoped=true&", function () {
      api.rerender('264ab5f9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/jflowPlugin/view/FormItemComponent.vue"
export default component.exports