var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ItemComponentRoot" }, [
    _c("span", { staticClass: "itemLabel" }, [
      _vm._items.required
        ? _c("span", { staticClass: "label-tip" }, [_vm._v("*")])
        : _vm._e(),
      _vm._v("\n    " + _vm._s(_vm._items.title) + ":\n  ")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "itemComponent" },
      [
        _vm._items.type === "input"
          ? _c("Input", {
              attrs: {
                type: _vm._items.props.type,
                clearable: _vm._items.props.clearable,
                disabled: _vm._items.props.disabled,
                readonly: _vm._items.props.readonly,
                rows: _vm._items.props.rows,
                autosize: _vm._items.props.autosize,
                number: _vm._items.props.number,
                autofocus: _vm._items.props.autofocus,
                placeholder: _vm._items.props.placeholder,
                size: _vm._items.props.size,
                maxlength: _vm._items.props.maxlength,
                icon: _vm._items.props.icon,
                regx: _vm._items.props.regx,
                "on-click": "inputClick",
                "on-blur": "inputBlur"
              },
              on: {
                "on-change": _vm.inputChange,
                "on-enert": _vm.inputEnter,
                "on-focus": _vm.inputFocus,
                "on-keyup": _vm.inputKeyUp,
                "on-keydown": _vm.inputKeyDown,
                "on-keypress": _vm.inputKeyPress,
                "on-regx-check": _vm.inputRegxCheck
              },
              model: {
                value: _vm._items.value,
                callback: function($$v) {
                  _vm.$set(_vm._items, "value", $$v)
                },
                expression: "_items.value"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "checkbox"
          ? _c("Checkbox", {
              attrs: {
                disabled: _vm._items.props.disabled,
                size: _vm._items.props.size,
                circle: _vm._items.props.circle
              },
              on: { "on-change": _vm.checkBoxChange },
              model: {
                value: _vm._items.value,
                callback: function($$v) {
                  _vm.$set(_vm._items, "value", $$v)
                },
                expression: "_items.value"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "select"
          ? _c(
              "Select",
              {
                attrs: {
                  clearable: _vm._items.props.clearable,
                  multiple: _vm._items.props.multiple,
                  "multiple-type": _vm._items.props.multipleType,
                  disabled: _vm._items.props.disabled,
                  placeholder: _vm._items.props.placeholder,
                  "not-found-text": _vm._items.props["not-found-text"],
                  "label-in-value": _vm._items.props["label-in-value"],
                  placement: _vm._items.props.placement,
                  transfer: _vm._items.props.transfer
                },
                on: {
                  "on-change": _vm.selectChange,
                  "on-clear": _vm.selectClear,
                  "on-open-change": _vm.selectOpenChange
                },
                model: {
                  value: _vm._items.value,
                  callback: function($$v) {
                    _vm.$set(_vm._items, "value", $$v)
                  },
                  expression: "_items.value"
                }
              },
              _vm._l(_vm._items.options, function(item) {
                return _c(
                  "Option",
                  {
                    key: item.value,
                    attrs: { value: item.value, disabled: item.disabled }
                  },
                  [_vm._v("\n        " + _vm._s(item.label) + "\n      ")]
                )
              }),
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "DatePicker"
          ? _c("DatePicker", {
              attrs: {
                type: _vm._items.props.type,
                transfer: _vm._items.props.transfer,
                format: _vm._items.props.format,
                placement: _vm._items.props.placement,
                placeholder: _vm._items.props.placeholder,
                options: _vm._items.props.options,
                open: _vm._items.props.open,
                confirm: _vm._items.props.confirm,
                size: _vm._items.props.size,
                disabled: _vm._items.props.disabled,
                clearable: _vm._items.props.clearable,
                readonly: _vm._items.props.readonly,
                editable: _vm._items.props.editable
              },
              on: {
                "on-change": function($event, event, instance) {
                  return _vm.datePickerChange(
                    (_vm._items.value = $event),
                    event,
                    instance,
                    _vm._items.props.type
                  )
                },
                "on-clear": _vm.datePickerClear
              },
              model: {
                value: _vm._items.value,
                callback: function($$v) {
                  _vm.$set(_vm._items, "value", $$v)
                },
                expression: "_items.value"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "DropDownSelectFilter"
          ? _c("DropDownSelectFilter", {
              attrs: {
                data: _vm._items.props.data,
                single: _vm._items.props.single,
                "total-row-count": _vm._items.props.totalRowCount,
                "page-size": _vm._items.props.pageSize,
                "auto-data": _vm._items.props.AutoData,
                disabled: _vm._items.props.disabled,
                hidecolumns: _vm._items.props.hidecolumns,
                "data-empty-message": _vm._items.props.dataEmptyMessage,
                "default-selected": _vm._items.props.defaultSelected,
                transfer: _vm._items.props.transfer,
                "columns-key": _vm._items.props.columnsKey,
                "show-colname-key": _vm._items.props.showColnameKey
              },
              on: {
                "on-fkrp-selected": _vm.fkrpSelected,
                "on-page-change": _vm.pageChange,
                "on-input-value-change": _vm.inputValueChange,
                "on-focus": _vm.fkrpSelectedInputFocus,
                "on-blur": _vm.fkrpSelectedInputBlur,
                "on-keyup": _vm.fkrpSelectedInputKeyup,
                "on-keydown": _vm.fkrpSelectedInputKeydown,
                "on-popper-show": _vm.fkrpSelectedPopperShow,
                "on-clear": _vm.fkrpSelectedClear
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }