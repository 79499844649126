var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "agree" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("Input", {
          attrs: { type: "textarea", rows: 4, placeholder: "请输入审批意见" },
          model: {
            value: _vm.agreecontent,
            callback: function($$v) {
              _vm.agreecontent = $$v
            },
            expression: "agreecontent"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "Button",
          {
            attrs: { disabled: _vm.buttonDisabled },
            on: { click: _vm.cancel }
          },
          [_vm._v("\n      取消\n    ")]
        ),
        _vm._v(" "),
        _c(
          "Button",
          {
            attrs: { type: "primary", disabled: _vm.buttonDisabled },
            on: { click: _vm.Agree }
          },
          [_vm._v("\n      确定\n    ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }