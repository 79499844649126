var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "FormComponent" }, [
    _c(
      "div",
      { staticClass: "FormItemComponent", style: _vm.setWidth },
      _vm._l(_vm.dataColRol, function(item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "FormItemComponent-item",
            style: _vm.setDiv(item)
          },
          [
            _c(item.component, {
              ref: "component_" + index,
              refInFor: true,
              tag: "component",
              attrs: { index: index, items: item.item },
              on: { inputChange: _vm.inputChange }
            })
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.buttonType
      ? _c(
          "p",
          [
            _c(
              "Button",
              { attrs: { type: "primary" }, on: { click: _vm.search } },
              [_vm._v("\n      搜索\n    ")]
            ),
            _vm._v(" "),
            _c(
              "Button",
              { attrs: { type: "fcdefault" }, on: { click: _vm.reset } },
              [_vm._v("\n      重置\n    ")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }