var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "InstanceManagementList" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _vm.tabConfig.length > 0
          ? _c(
              "Tabs",
              {
                attrs: { value: _vm.tabalive },
                on: { "on-click": _vm.onClick }
              },
              _vm._l(_vm.tabConfig, function(item, index) {
                return _c(
                  "TabPane",
                  { key: index, attrs: { label: item.label, name: item.name } },
                  [
                    _c(
                      "keep-alive",
                      [
                        _vm.currentComponents === item.name
                          ? _c(_vm.currentComponents, {
                              ref: item.name,
                              refInFor: true,
                              tag: "components",
                              attrs: { tabalive: _vm.tabalive }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }