<template>
  <div class="TodoProcess">
    <div class="btn-agent">
      <div class="btnArea">
        <Button
          type="primary"
          size="small"
          @click="searchData.page = 1;queryLists()"
        >
          查询
        </Button>
        <Button
          v-if="tabalive === 'todoList'"
          size="small"
          ghost
          @click="openModal(0)"
        >
          同意
        </Button>
        <Button
          v-if="tabalive === 'todoList'"
          size="small"
          ghost
          @click="openModal(1)"
        >
          驳回
        </Button>
        <Button
          v-if="tabalive === 'todoList'"
          size="small"
          ghost
          @click="openModal(2)"
        >
          转派
        </Button>
        <Button
          v-if="tabalive === 'todoList'"
          size="small"
          ghost
          @click="openModal(3)"
        >
          设置外出代理人
        </Button>
        <Button
          v-if="tabalive === 'todoList' && Agent.AGENT_ENAME"
          size="small"
          ghost
          @click="cancelAgent"
        >
          取消代理人
        </Button>
        <Button
          v-if="tabalive === 'launchList'"
          ghost
          size="small"
          @click="Back"
        >
          撤销
        </Button>
        <Button
          ghost
          size="small"
          :loading="loading"
          v-if="tabalive !== 'copyList'"
          @click="exportData"
        >
          导出
        </Button>
      </div>
      <span
        v-if="tabalive === 'todoList' && Agent.AGENT_ENAME"
        class="agentshow"
      >代理人：{{ Agent.AGENT_ENAME }}</span>
    </div>
    <FormItemComponent
      class="form"
      :form-item-lists="formLists"
      :button-type="false"
      @formChange="formChange"
    />

    <div class="StandardTable">
      <Spin
        v-if="spinShow"
        size="large"
        fix 
      />
      <StandardTable
        :current-page="searchData.page"
        :page-size="searchData.pageSize"
        :total="total"
        :columns="columns"
        :data="data"
        :standard-table-event="standardTableEvent"
      />
    </div>
    
    <Modal
      v-model="openControl"
      :title="modaltitle"
      :mask="true"
      :width="835"
    >
      <div
        class="modalCotent"
      >
        <mutipleSelectPop
          v-if="openControl"
          ref="dialogtest"
          :node-id="modaltype !== 3?currentNodeId:''"
          :is-use="false"
          :is-mutiple="false"
          @getResult="getResult"
        />
      </div>
      <div slot="footer" class="footclass">
        <Button
          @click="cancel"
        >
          取消
        </Button>
        <Button
          type="primary"
          @click="ok"
        >
          确定
        </Button>
      </div>
    </Modal>
    <Modal
      v-model="openControlAg"
      :title="modaltitleAg"
      :mask="true"
      :width="400"
    >
      <div class="modalCotent">
        <Input
            v-model="description"
            type="textarea"
            :rows="4"
            placeholder="请输入审批意见"
      />
      </div>
      <div slot="footer">
        <Button
          @click="cancelAg"
        >
          取消
        </Button>
        <Button
          type="primary"
          @click="Agree"
        >
          确定
        </Button>
      </div>
    </Modal>
  </div>
</template>
<script>
  import FormItemComponent from './FormItemComponent';
  import ItemComponent from './ItemComponent';
  import StandardTable from './StandardTable';
  import mutipleSelectPop from './MutipleSelectPop';
  import network from '../utils/network';
  import { BacklogData } from '../js/todoList';
  import { global, globalChange } from '../utils/global.config';

  export default {
    components: { FormItemComponent, StandardTable, mutipleSelectPop },
    props: {
      tabalive: { type: String, default: '' }
    },
    data() {
      return {
        urlMap: { // 请求数据url映射
          todoList: '/jflow/p/cs/task/backlog/list', // 待我审批
          approvalList: '/jflow/p/cs/task/history/list', // 我已审批
          launchList: '/jflow/p/cs/task/initiator/list', // 我发起的
          copyList: '/jflow/p/cs/task/cslist' // 我抄送的
        },
        headerUrlMap: {
          todoList: 'upcoming',
          approvalList: 'approved',
          launchList: 'initiated',
          copyList: 'carbon-copied'
        },
        modaltype: 0,
        openControl: false, // 弹框是否显示控制
        openControlAg: false, // 弹框是否显示控制
        modaltitle: '', // 弹框标题
        modaltitleAg: '审批意见',
        // 待办-同意意见/驳回意见
        description: '', // 同意意见
        ids: [],
        loading: false,
        actList: [0,1],

        // 表单配置
        formLists: [],
        options: [], // 单据类型选项
        // 查询条件
        searchData: {
          page: 1,
          pageSize: 10,
          searchType: '0,1',
          excuStatus: 0,
          createTime: [new Date(new Date(new Date().getTime() - 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)).minusDays(29).format('yyyy-MM-dd hh:mm:ss'), new Date(new Date().setHours(23, 59, 59)).format('yyyy-MM-dd hh:mm:ss')]
        },
        // 表格数据
        total: 0,
        columns: [], // 表头字段
        data: [],
        standardTableEvent: {
          'on-change': (page) => {
            this.searchData.page = page;
            this.queryLists();
          },
          'on-page-size-change': (pageSize) => {
            this.searchData.page = 1;
            this.searchData.pageSize = pageSize;
            this.queryLists();
          },
          'on-row-click': (row) => {
            if (global.changePattern) {
              const self = this;
              self.currentNodeId = String(row.nodeId);
              self.data.map((item) => {
                if (item.id === row.id) {
                  item._check = true;
                } else {
                  item._check = false;
                }
                return item;
              });
            }
          },
          'on-select': (selection, row) => {
            // 表格行选中事件
            const self = this;
            self.data.map((item) => {
              if (item.id === row.id) {
                item._check = true;
                self.ids.push(item.id)
              }
              return item;
            });
          },
          'on-select-cancel': (selection, row) => {
            // 表格行取消事件
            const self = this;
            self.data.map((item) => {
              if (item.id === row.id) {
                item._check = false;
                self.ids = self.ids.filter(e=>item.id !== e)
              }
              return item;
            });
          },
          'on-select-all': () => {
            // 表格行全部选中
            const self = this;
            self.data.map((item) => {
              item._check = true;
              self.ids.push(item.id)
              return item;
            });
          },
          'on-select-all-cancel': () => {
            // 表格行全部取消
            const self = this;
            self.data.map((item) => {
              item._check = false;
              self.ids = [];
              return item;
            });
          },
          'on-sort-change': (row) => {
            if (row.order === 'normal') {
              delete this.searchData.sortable;
              delete this.searchData.sortType;
            } else {
              this.searchData.sortable = row.key;
              this.searchData.sortType = row.order;
            }
            
            this.searchData.page = 1;
            this.queryLists();
          }
        },
        // findValue: "", //input搜索框的输入值

        resultData: {}, // 选中结果

        spinShow: false,
        Agent: {}, // 代理人信息

        detail: {}, // 点击的单据
      };
    },
    methods: {
      urlParse(path) {
        const url = path || window.location.search; // 得到url问号后面拼接的参数  ?id=12345&a=b
        const obj = {}; // 创建一个Object
        const reg = /[?&][^?&]+=[^?&]+/g; // 正则匹配 ?&开始 =拼接  非?&结束  的参数
        const arr = url.match(reg); // match() 方法可在字符串内检索指定的值，或找到一个或多个正则表达式的匹配。
        // arr数组形式 ['?id=12345','&a=b']
        if (arr) {
          arr.forEach((item) => {
            /**
             * tempArr数组    ['id','12345']和['a','b']
             * 第一个是key，第二个是value
             * */
            const tempArr = item.substring(1).split('=');
            const key = decodeURIComponent(tempArr[0]);
            const val = decodeURIComponent(tempArr[1]);
            obj[key] = val;
          });
        }
        return obj;
      },
      // 获取表头数据
      getHeader() {
        const gridName = this.headerUrlMap[this.tabalive];
        network.post('/jflow/p/cs/sys/grid/header', {
          userId: global.userInfo.id,
          gridName
        }).then((res) => {
          if (res.data.resultCode === 0) {
            res.data.data.headers = res.data.data.headers.map((item, index) => {
              // 待审批人
              if (item.key === 'approverValue') {
                item.render = (h, params) => h(
                  'p',
                  {
                    style: {
                      maxWidth: '160px',
                      overflow: 'hidden',
                      'text-overflow': 'ellipsis',
                      'white-space': 'nowrap'
                    }
                  },
                  params.row.approvers.join(',')
                );
              }

              // 流程状态
              if (item.key === 'processStatusName') {
                item.render = (h, params) => {
                  if (params.row.processStatus === 4) {
                    return h('Poptip', {
                      props: {
                        trigger: 'hover',
                        content: params.row.submitErrorMsg,
                        transfer: true
                      }
                    }, [h(
                      'span',
                      {
                        style: {
                          color: 'rgba(255, 0, 0, 1)',
                          cursor: 'pointer'
                        }
                      },
                      params.row.processStatusName
                    )]);
                  }
                  return h(
                    'p',
                    {
                      style: {
                        maxWidth: '160px',
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                        'white-space': 'nowrap'
                      }
                    },
                    params.row.processStatusName
                  );
                };
              }

              // 处理默认排序
              if (item.sortType && item.sortable) {
                this.searchData.sortable = item.sortable;
                this.searchData.sortType = item.sortType;
              }
              return item;
            });
            res.data.data.headers.push({
              title: '详情',
              fixed: 'right',
              render: (h, params) => h(
                'p',
                {
                  style: {
                    display: 'flex',
                    'align-items': 'center'
                  // "justify-content": "center"
                  }
                },
                [
                  h(
                    'span',
                    {
                      style: {
                        color: 'rgba(16, 142, 233, 1)',
                        cursor: 'pointer'
                      },
                      on: {
                        click: () => {
                          let obj = {};
                          const key = `${params.row.businessValue}${params.row.businessCode}`;
                          if (global.jflowInfoMap) {
                            obj = Object.assign({}, obj, global.jflowInfoMap);
                            obj = Object.assign({}, obj, {
                              [key]: {
                                instanceId: params.row.instanceId,
                                nodeId: params.row.nodeId,
                                taskId: params.row.id
                              }
                            });
                          } else {
                            obj = Object.assign({}, obj, {
                              [key]: {
                                instanceId: params.row.instanceId,
                                nodeId: params.row.nodeId,
                                taskId: params.row.id
                              }
                            });
                          }
                          globalChange({
                            jflowInfoMap: obj,
                            jflowInfo: {
                              instanceId: params.row.instanceId,
                              nodeId: params.row.nodeId,
                              taskId: params.row.id
                            }
                          });
                          if (params.row.formUrl.includes('/SYSTEM')) {
                            const distype = params.row.objdistype === 'tabpanle' ? 'H' : 'V';
                            const arr = params.row.formUrl.split('/');
                            arr[3] = distype;
                            window.sessionStorage.setItem('dynamicRouting', true);
                            window.vm.$router.push({
                              path: arr.join('/')
                            });
                          } else {
                            window.sessionStorage.setItem('dynamicRouting', true);
                            window.vm.$router.push({
                              path: params.row.formUrl
                            });
                          }
                        }
                      }
                    },
                    '查看明细'
                  ),
                  h('span', {
                    style: {
                      width: '1px',
                      height: '19px',
                      display: 'inline-block',
                      background: 'rgba(228,228,228,1)',
                      margin: '0 16px'
                    }
                  }),
                  h(
                    'span',
                    {
                      style: {
                        color: 'rgba(16, 142, 233, 1)',
                        cursor: 'pointer'
                      },
                      on: {
                        click: () => {
                          window.open(
                            `${global.localIp}/#/FlowChart?instanceId=${params.row.instanceId ? params.row.instanceId : params.row.lastInstanceId}`,
                            '_blank',
                            'width=861,height=612'
                          );
                        }
                      }
                    },
                    '流程进度'
                  )
                ]
              )
            });
            // 控制转派的选择模式
            if (global.changePattern && this.tabalive === 'todoList') {
              res.data.data.headers.splice(0, 1);
            }
            this.columns = res.data.data.headers;
          }
        });
      },
      // 获取查询条件
      getFormLists() {
        const gridName = this.headerUrlMap[this.tabalive];
        network.post('/jflow/p/cs/sys/grid/search/area', {
          userId: global.userInfo.id,
          gridName
        })
          .then((res) => {
            res.data.data.searchArea = res.data.data.searchArea.map((item) => {
              const temp = {
                row: 1,
                col: 1,
                component: ItemComponent,
                item
              };
              if (temp.item.type === 'input') {
                temp.item.event = {
                  keydown: (event) => {
                    if (event.keyCode === 13) {
                      this.searchData.page = 1;
                      this.queryLists();
                    }
                  }
                };
              }
              if (temp.item.field === 'instanceId') { // 工作流编号只能输入数字
                temp.item.props = {
                  regx: /^[0-9]*$/
                };
              }

              if (temp.item.field === 'businessType') { // 单据类型
                temp.item.options = this.options;
              }

              if (temp.item.field === 'createTime') { // 创建时间设置默认值
                temp.item.value = [new Date(new Date(new Date().getTime() - 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)).minusDays(29).format('yyyy-MM-dd hh:mm:ss'), new Date(new Date().setHours(23, 59, 59)).format('yyyy-MM-dd hh:mm:ss')];
              }

              return temp;
            });
            this.formLists = res.data.data.searchArea;
          });
      },
      // 业务关系下拉数据
      async getselectOption() {
        let type = null;
        switch (this.tabalive) {
        case 'todoList': 
          type = 0; break;
        case 'approvalList': 
          type = 1; break;
        case 'launchList': 
          type = 2; break;
        case 'copyList': 
          type = 3; break;
        default: break;
        }
        await network.post('/jflow/p/cs/task/relation/list', {
          userId: global.userInfo.id,
          listType: type
        }).then((res) => {
          if (res.data.resultCode === 0) {
            this.options = res.data.data.relations.map((item) => {
              item.value = item.businesskey;
              item.label = item.businessName;
              return item;
            });
          }
        });
      },
      // 查询
      queryLists() {
        this.spinShow = true;
        if (
          this.searchData.createTime
          && this.searchData.createTime[0]
          && this.searchData.createTime[1]
        ) {
          this.searchData.startTime = this.searchData.createTime[0];
          this.searchData.endTime = this.searchData.createTime[1];
        } else {
          this.searchData.startTime = '';
          this.searchData.endTime = '';
        }
        const obj = Object.assign({}, this.searchData);
        const gridName = this.headerUrlMap[this.tabalive];
        delete obj.createTime;
        obj.userId = global.userInfo.id;
        obj.gridName = gridName;
        network
          .post(this.urlMap[this.tabalive], obj)
          .then((res) => {
            if (res.data.resultCode === 0) {
              const data = res.data.data;
              this.total = data.total;
              this.data = data.records;
            }
            this.spinShow = false;
            this.ids = [];
          })
          .catch(() => {
            this.spinShow = false;
          });
      },
      formChange(data) {
        // 表单数据修改时，修改searchData数据
        this.searchData = Object.assign({}, this.searchData, data);
        if (
          Object.prototype.toString.call(this.searchData.businessType)
          === '[object Array]'
          && this.searchData.businessType.length === 0
        ) {
          delete this.searchData.businessType;
        }
      },
      Back() {
        if (!this.ids.length) {
          this.$Message.warning('请选择要撤销的数据');
          return;
        }
        this.$Modal.fcWarning({
          title: '警告',
          content: '确定要进行撤销操作吗？',
          mask: true,
          showCancel: true,
          onOk: () => {
            const payload = {
              userId: global.userInfo.id,
              ids: this.ids.join()
            };
            network
              .post('/jflow/p/cs/task/page/back',payload)
              .then((res) => {
                if (res.data.resultCode === 0) {
                  this.$Message.success(res.data.resultMsg);
                  this.queryLists();
                } else {
                    this.$Modal.fcError({
                    title: '错误',
                    content: res.data.resultMsg,
                    mask: true,
                    onOk: () => {
                      this.queryLists();
                    }
                  });
                }
              });
          }
        });
      },
      Agree() {
        const payload = {
          userId: global.userInfo.id,
          description: this.description,
          actType: this.actList[this.modaltype],
          ids: this.ids.join()
        };
        network.post('/jflow/p/cs/task/batch/deal',payload).then(res => {
              if (res.data.resultCode == 0) {
                this.$Message.success(res.data.resultMsg);
                this.queryLists();
                this.openControlAg = false;
              } else {
                this.$Message.error(res.data.resultMsg)
                this.description = '';
                this.openControlAg = false;
              }
            }).catch(() => {});
      },
      // 0同意/1驳回、2转派
      batchoperate() {
        const self = this;
        const sendData = {};
        sendData.ids = '';
        self.data.map((item) => {
          if (item._check) {
            sendData.ids += `${item.id},`;
          }
          return item;
        });
        sendData.ids = sendData.ids.substring(0, sendData.ids.length - 1);
        if (this.resultData.list && this.resultData.list.length > 0) {
          sendData.delegateId = this.resultData.list[0].ID;
        }
        // }else{
        //   return
        // }
        // sendData.userId = this.userInfo.userId;
        network.post('/jflow/p/cs/task/batch/deal', sendData).then((res) => {
          const data = res.data;
          if (data.resultCode === 0) {
            this.openControl = false; // 关闭弹框
            this.resultData.list = [];
            this.$Message.success(data.resultMsg);
            this.queryLists();
            BacklogData(window.jflowPlugin.store);
          } else {
            this.$Message.warning(data.resultMsg);
          }
        });
      },
      ok() {
        if (!(this.resultData.list && this.resultData.list.length > 0)) {
          this.$Message.warning('请选择人员');
          return;
        }
        if (this.modaltype === 3) {
          this.setAgent();
        } else {
          this.batchoperate();
        }
        if (this.resultData.list && this.resultData.list.length > 0) {
          this.resultData.list = [];
          this.resultData.total = 0;
        }
      },
      cancel() {
        if (this.resultData.list && this.resultData.list.length > 0) {
          this.resultData.list = [];
          this.resultData.total = 0;
        }
        this.openControl = false; // 关闭弹框
      },
      cancelAg() {
        this.description = ''
        this.openControlAg = false; // 关闭弹框
      },
      async openModal(type) {
        this.description = '';
        this.modaltype = type;
        // eslint-disable-next-line no-unused-expressions
        type === 2
          ? (this.modaltitle = '选择转派人')
          : (this.modaltitle = '选择代理人');
        const tableSelectd = [];
        this.data.map((item) => {
          if (item._check) {
            tableSelectd.push(item);
          }
          return item;
        });
        // 同意或者驳回
        if (type === 1 || type === 0) {
          if (tableSelectd.length > 0) {
            this.openControlAg = true;
          } else {
            this.$Message.warning('请选择单据');
          }
        }
        if (type === 2) {
          if (tableSelectd.length > 0) {
            this.openControl = true;
          } else {
            this.$Message.warning('请选择单据');
          }
        }
        if (type === 3) {
          this.openControl = true;
        }

        if (type === 'detail') {
          this.openControl = true;
          this.detail = detail;
          this.modaltitle = '单据详情';
        }
      },
      
      // 设置外出代理人
      setAgent() {
        const needdata = {};
        needdata.USER_ID = global.userInfo.id;
        needdata.AGENT_ENAME = this.resultData.list[0].ENAME;
        if (this.resultData.list && this.resultData.list.length > 0) {
          needdata.AGENT_ID = Number(this.resultData.list[0].ID);
        }
        network.post('/jflow/p/c/agent/save', needdata).then((res) => {
          if (res.data.resultCode === 0) {
            this.resultData.list = [];
            // this.getAgent();
            this.Agent = {
              AGENT_ENAME: needdata.AGENT_ENAME,
              AGENT_ID: needdata.AGENT_ID,
              USER_ID: global.userInfo.id
            };
            this.queryLists();
            this.$Message.success(res.data.resultMsg);
            this.openControl = false; // 关闭弹框
          } else {
            this.$Message.warning(res.data.resultMsg);
          }
        });
      },
      async getAgent() {
        // 获取代理人信息
        await network
          .post('/jflow/p/c/agent/get', {
            USER_ID: global.userInfo.id
          })
          .then((res) => {
            if (res.data.resultCode === 0) {
              this.Agent = res.data.data;
            }
          });
      },
      cancelAgent() {
        this.$Modal.fcWarning({
          title: '警告',
          content: '确定要取消代理人吗？',
          mask: true,
          showCancel: true,
          onOk: () => {
            network
              .post('/jflow/p/c/agent/delete', { USER_ID: this.Agent.USER_ID })
              .then((res) => {
                if (res.data.resultCode === 0) {
                  this.getAgent();
                  this.$Message.success(res.data.resultMsg);
                } else {
                  this.$Message.warning(res.data.resultMsg);
                }
              });
          }
        });
      },
      /**
       * 导出
       * src 导出路径
       */
      downloadUrlFile(url) {
        const self = this;
        const domFrame = window.parent.document.getElementById('downLoadListFrame');
        if (domFrame != null) {
          window.parent.document.body.removeChild(domFrame);
        }
        const downloadFile = {};
        if (typeof downloadFile.iframe === 'undefined') {
          const iframe = document.createElement('iframe');
          iframe.setAttribute('id', 'downLoadListFrame');
          self.addEvent('load', iframe, () => {
            self.iframeLoad(iframe);
          });
          iframe.src = url;
          iframe.style.display = 'none';
          downloadFile.iframe = iframe;
          document.body.appendChild(downloadFile.iframe);
          setTimeout(() => {
            iframe.src = '';
          }, 1000);
        }
      },
        // 判断iframe的src
      iframeLoad(iframe) {
        const src = iframe.src ? iframe.src : iframe.contentWindow.locatiion.href;
      },
      // 调用方法时绑定iframe的load事件
      addEvent(eventName, element, fn) {
        if (element.attachEvent) element.attachEvent(`on${eventName}`, fn);
        else element.addEventListener(eventName, fn, false);
      },
      // 导出数据
      exportData() {
        const gridName = this.headerUrlMap[this.tabalive];
        const payload = {
              userId: global.userInfo.id,
              gridName,
              ids: this.ids.join()
            };
        const obj = Object.assign(payload,this.searchData)
        this.loading = true;
            network
              .post('/jflow/p/cs/task/exportProcess',obj)
              .then((res) => {
                if (res.data.resultCode === 0) {
                  this.loading = false;
                  this.$Message.success('导出成功');
                  this.downloadUrlFile(res.data.resultMsg)
                }
              });
      },

      // 获取选中的数据
      getResult(data) {
        this.resultData = data;
      }
    },
    async created() {
      if (global.userInfo) {
        this.getHeader();
        await this.getselectOption();
        this.getFormLists();
      } else {
        const timer = setInterval(async () => {
          if (global.userInfo) {
            clearInterval(timer);
            this.getHeader();
            await this.getselectOption();
            this.getFormLists();
          }
        }, 10);
      }
    },
    async activated() {
      if (global.userInfo) {
        this.queryLists();
        if (this.tabalive === 'todoList') {
          // 获取外出代理人数据
          this.getAgent();
        }
      } else {
        const timer = setInterval(async () => {
          if (global.userInfo) {
            clearInterval(timer);
            this.queryLists();
            if (this.tabalive === 'todoList') {
              // 获取外出代理人数据
              this.getAgent();
            }
          }
        }, 10);
      }
    }
  };
</script>
<style lang="less">
.ark-spin-fix {
  z-index: 100;
}

.TodoProcess .btn-agent .btnArea .ark-btn {
  margin-right: 8px;
  margin-left: 0;
  padding: 0 8px;
  margin-bottom: 4px;
  font-weight: 400;
  height: 28px;
}
.ark-btn-ghost.ark-btn-default {
  color: #fd6442 !important;
  background-color: transparent !important;
  border-color: #fd6442 !important;
}
.ark-btn-ghost.ark-btn-default:hover {
  opacity: 0.6;
}
.TodoProcess {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .btn-agent {
    display: flex;
    justify-content: space-between;
    .btnArea {
      margin-bottom: 10px;
      .ark-btn {
        margin-right: 9px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .agentshow {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      margin-bottom: 10px;
    }
  }

  .form {
    margin-bottom: 16px;
  }

  .StandardTable {
    position: relative;
    flex: 1;
    display: flex;
    overflow: hidden;
    .table {
      flex: 1;
    }
  }

.ark-btn-default {
  color: #fd6442 !important;
  background-color: transparent !important;
  border-color: #fd6442 !important;
}
.ark-btn-default:hover {
  opacity: 0.6;
}
.ark-btn-primary {
  color: #fff;
  background-color: #ed4014 !important;
  border-color: #fd6442 !important;
}
.ark-btn-primary:hover {
  opacity: 0.6;
}
}
.footclass {
  .ark-btn-default {
    color: #fd6442 !important;
    background-color: transparent !important;
    border-color: #fd6442 !important;
  }
  .ark-btn-default:hover {
    opacity: 0.6;
  }
  .ark-btn-primary {
    color: #fff;
    background-color: #ed4014 !important;
    border-color: #fd6442 !important;
  }
  .ark-btn-primary:hover {
    opacity: 0.6;
  }
}
.modalCotent {
  .ark-tree {
    overflow-y: auto;
  }
}
</style>
