var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            "class-name": "ApprovelModel",
            title:
              _vm.type === "3"
                ? "选择转派人"
                : _vm.type === "9"
                ? "人工干预"
                : "审批意见",
            mask: true,
            "mask-closable": false,
            width: _vm.type === "3" ? 835 : 520,
            "footer-hide": true
          },
          model: {
            value: _vm.modalConfig.control,
            callback: function($$v) {
              _vm.$set(_vm.modalConfig, "control", $$v)
            },
            expression: "modalConfig.control"
          }
        },
        [
          _c(_vm.currentComponent, {
            tag: "component",
            attrs: { "modal-config": _vm.modalConfig }
          }),
          _vm._v(" "),
          false
            ? _c("div", { staticClass: "ApprovelModel Intervention" }, [
                _c("div", { staticClass: "details" }, [
                  _c("p", { staticClass: "title" }, [
                    _c("span"),
                    _vm._v(" "),
                    _c("span", [_vm._v("报错信息：")])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("p", [
                      _vm._v(
                        "\n            流程报错:" +
                          _vm._s(_vm.intervention.errorType) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            错误简述:" +
                          _vm._s(_vm.intervention.errorMsg) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            所在节点:" +
                          _vm._s(_vm.intervention.currentNodeName) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            建议措施:" +
                          _vm._s(_vm.intervention.handleOpinion) +
                          "\n          "
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "deal" }, [
                  _c("p", { staticClass: "title" }, [
                    _c("span"),
                    _vm._v(" "),
                    _c("span", [_vm._v("人工干预处理：")])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { "padding-left": "20px" } }, [
                    Number(_vm.intervention.errorCode) === 47
                      ? _c("div", [
                          _c(
                            "p",
                            [
                              _c("label", [_vm._v("URL:")]),
                              _vm._v(" "),
                              _c("Input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.intervention.handleUrl,
                                  callback: function($$v) {
                                    _vm.$set(_vm.intervention, "handleUrl", $$v)
                                  },
                                  expression: "intervention.handleUrl"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            [
                              _c("label", [_vm._v("服务参数:")]),
                              _vm._v(" "),
                              _c("Input", {
                                staticClass: "textarea",
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 3, maxRows: 3 }
                                },
                                model: {
                                  value: _vm.intervention.handleParam,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.intervention,
                                      "handleParam",
                                      $$v
                                    )
                                  },
                                  expression: "intervention.handleParam"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    Number(_vm.intervention.errorCode) === 5
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-bottom": "8px",
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _c("label", [_vm._v("干预至某节点:")]),
                            _vm._v(" "),
                            _c(
                              "Select",
                              {
                                staticClass: "checkSelect",
                                staticStyle: {
                                  flex: "1",
                                  "margin-left": "10px"
                                },
                                attrs: { "label-in-value": "" },
                                model: {
                                  value: _vm.selectedNode,
                                  callback: function($$v) {
                                    _vm.selectedNode = $$v
                                  },
                                  expression: "selectedNode"
                                }
                              },
                              _vm._l(_vm.intervention.mannalNodes, function(
                                item
                              ) {
                                return _c(
                                  "Option",
                                  {
                                    key: item.nodeName,
                                    attrs: { value: item.nodeId }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.nodeName) +
                                        "\n              "
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    Number(_vm.intervention.errorCode) === 33
                      ? _c("div", [
                          _c(
                            "p",
                            [
                              _c("label", [_vm._v("人员指派:")]),
                              _vm._v(" "),
                              _c("ComplexBox", {
                                on: { getTotalResult: _vm.getTotalResult }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    Number(_vm.intervention.errorCode) === 53
                      ? _c(
                          "div",
                          [
                            _c(
                              "RadioGroup",
                              {
                                attrs: { vertical: "" },
                                on: {
                                  "on-change": function($event) {
                                    _vm.selectBackNode = null
                                  }
                                },
                                model: {
                                  value: _vm.submitType,
                                  callback: function($$v) {
                                    _vm.submitType = $$v
                                  },
                                  expression: "submitType"
                                }
                              },
                              [
                                _c("Radio", { attrs: { label: 0 } }, [
                                  _c("span", [_vm._v("重新提交")])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c(
                                      "Radio",
                                      {
                                        staticStyle: { "margin-right": "2px" },
                                        attrs: { label: 1 }
                                      },
                                      [_c("span", [_vm._v("驳回至")])]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "Select",
                                      {
                                        staticClass: "checkSelect",
                                        attrs: {
                                          "label-in-value": "",
                                          disabled: _vm.submitType === 0
                                        },
                                        model: {
                                          value: _vm.selectBackNode,
                                          callback: function($$v) {
                                            _vm.selectBackNode = $$v
                                          },
                                          expression: "selectBackNode"
                                        }
                                      },
                                      _vm._l(
                                        _vm.intervention.mannalNodes,
                                        function(item) {
                                          return _c(
                                            "Option",
                                            {
                                              key: item.nodeName,
                                              attrs: { value: item.nodeId }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(item.nodeName) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "p",
                      [
                        _c("label", [_vm._v("备注:")]),
                        _vm._v(" "),
                        _c("Input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.remark,
                            callback: function($$v) {
                              _vm.remark = $$v
                            },
                            expression: "remark"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }