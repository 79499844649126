(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define("jflowPlugin", ["vue", "axios"], factory);
	else if(typeof exports === 'object')
		exports["jflowPlugin"] = factory(require("vue"), require("axios"));
	else
		root["jflowPlugin"] = factory(root["Vue"], root["axios"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__32__) {
return 