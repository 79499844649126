var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "TodoProcess" },
    [
      _c("div", { staticClass: "btn-agent" }, [
        _c(
          "div",
          { staticClass: "btnArea" },
          [
            _c(
              "Button",
              {
                attrs: { type: "primary", size: "small" },
                on: {
                  click: function($event) {
                    _vm.searchData.page = 1
                    _vm.queryLists()
                  }
                }
              },
              [_vm._v("\n        查询\n      ")]
            ),
            _vm._v(" "),
            _vm.tabalive === "todoList"
              ? _c(
                  "Button",
                  {
                    attrs: { size: "small", ghost: "" },
                    on: {
                      click: function($event) {
                        return _vm.openModal(0)
                      }
                    }
                  },
                  [_vm._v("\n        同意\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.tabalive === "todoList"
              ? _c(
                  "Button",
                  {
                    attrs: { size: "small", ghost: "" },
                    on: {
                      click: function($event) {
                        return _vm.openModal(1)
                      }
                    }
                  },
                  [_vm._v("\n        驳回\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.tabalive === "todoList"
              ? _c(
                  "Button",
                  {
                    attrs: { size: "small", ghost: "" },
                    on: {
                      click: function($event) {
                        return _vm.openModal(2)
                      }
                    }
                  },
                  [_vm._v("\n        转派\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.tabalive === "todoList"
              ? _c(
                  "Button",
                  {
                    attrs: { size: "small", ghost: "" },
                    on: {
                      click: function($event) {
                        return _vm.openModal(3)
                      }
                    }
                  },
                  [_vm._v("\n        设置外出代理人\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.tabalive === "todoList" && _vm.Agent.AGENT_ENAME
              ? _c(
                  "Button",
                  {
                    attrs: { size: "small", ghost: "" },
                    on: { click: _vm.cancelAgent }
                  },
                  [_vm._v("\n        取消代理人\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.tabalive === "launchList"
              ? _c(
                  "Button",
                  {
                    attrs: { ghost: "", size: "small" },
                    on: { click: _vm.Back }
                  },
                  [_vm._v("\n        撤销\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.tabalive !== "copyList"
              ? _c(
                  "Button",
                  {
                    attrs: { ghost: "", size: "small", loading: _vm.loading },
                    on: { click: _vm.exportData }
                  },
                  [_vm._v("\n        导出\n      ")]
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _vm.tabalive === "todoList" && _vm.Agent.AGENT_ENAME
          ? _c("span", { staticClass: "agentshow" }, [
              _vm._v("代理人：" + _vm._s(_vm.Agent.AGENT_ENAME))
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("FormItemComponent", {
        staticClass: "form",
        attrs: { "form-item-lists": _vm.formLists, "button-type": false },
        on: { formChange: _vm.formChange }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "StandardTable" },
        [
          _vm.spinShow
            ? _c("Spin", { attrs: { size: "large", fix: "" } })
            : _vm._e(),
          _vm._v(" "),
          _c("StandardTable", {
            attrs: {
              "current-page": _vm.searchData.page,
              "page-size": _vm.searchData.pageSize,
              total: _vm.total,
              columns: _vm.columns,
              data: _vm.data,
              "standard-table-event": _vm.standardTableEvent
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Modal",
        {
          attrs: { title: _vm.modaltitle, mask: true, width: 835 },
          model: {
            value: _vm.openControl,
            callback: function($$v) {
              _vm.openControl = $$v
            },
            expression: "openControl"
          }
        },
        [
          _c(
            "div",
            { staticClass: "modalCotent" },
            [
              _vm.openControl
                ? _c("mutipleSelectPop", {
                    ref: "dialogtest",
                    attrs: {
                      "node-id": _vm.modaltype !== 3 ? _vm.currentNodeId : "",
                      "is-use": false,
                      "is-mutiple": false
                    },
                    on: { getResult: _vm.getResult }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "footclass",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("Button", { on: { click: _vm.cancel } }, [
                _vm._v("\n        取消\n      ")
              ]),
              _vm._v(" "),
              _c(
                "Button",
                { attrs: { type: "primary" }, on: { click: _vm.ok } },
                [_vm._v("\n        确定\n      ")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "Modal",
        {
          attrs: { title: _vm.modaltitleAg, mask: true, width: 400 },
          model: {
            value: _vm.openControlAg,
            callback: function($$v) {
              _vm.openControlAg = $$v
            },
            expression: "openControlAg"
          }
        },
        [
          _c(
            "div",
            { staticClass: "modalCotent" },
            [
              _c("Input", {
                attrs: {
                  type: "textarea",
                  rows: 4,
                  placeholder: "请输入审批意见"
                },
                model: {
                  value: _vm.description,
                  callback: function($$v) {
                    _vm.description = $$v
                  },
                  expression: "description"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("Button", { on: { click: _vm.cancelAg } }, [
                _vm._v("\n        取消\n      ")
              ]),
              _vm._v(" "),
              _c(
                "Button",
                { attrs: { type: "primary" }, on: { click: _vm.Agree } },
                [_vm._v("\n        确定\n      ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }